<template>
    <section class="register section-offset">
        <UiLoader v-if="loading" :loading="true" theme="btn" color="#000" />
        <template v-if="!uplineData">
            <Registry @onUplineSelect="handleUplineSelect" />
        </template>

        <template v-if="uplineData">
            <SignUp :upline="uplineData" />
        </template>
    </section>
</template>

<script>
import { mapActions } from "vuex"
import Registry from "@/components/Register/Registry.vue"
import SignUp from "@/components/Register/SignUp.vue"
// import { DICT } from "@/config/constants"
import { LSTORAGE } from "@/config/constants"
import { getLocalStorageElement } from "@/helpers/localstorage"

export default {
    name: "Register",
    components: {
        Registry,
        SignUp
    },
    data: () => ({
        uplineData: null,
        loading: false,
    }),
    async mounted() {
        // const shouldCheck = this.$route.query.ignoreUplineRef === undefined
        const hasUpline = getLocalStorageElement(LSTORAGE.referral)
        if (hasUpline) {
            this.loading = true
            
            this.uplineData = hasUpline

            this.loading = false
        }
        // if (shouldCheck && getLocalStorageElement(LSTORAGE.referral)) {
        //     this.$swal(`${this.$t("register.useReferral")}`)
        //     this.$router.push({
        //         name: "referral",
        //         params: { referral: getLocalStorageElement(LSTORAGE.referral) }
        //     })
        // }
    },
    methods: {
        handleUplineSelect(data) {
            this.uplineData = data
        },
        ...mapActions("user", ["getUsers", "getUserByField"])

    }
}
</script>

<style lang="scss">
.register-more {
    padding: 11px;
    border-radius: 5px;
    background-color: var(--color-emerald);
    max-width: 452px;
    width: 100%;
    margin-top: var(--main-offset);
}
</style>
